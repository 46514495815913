export var TransactionTypePastTense;
(function (TransactionTypePastTense) {
  TransactionTypePastTense["approve"] = "approved";
  TransactionTypePastTense["bought"] = "bought";
  TransactionTypePastTense["borrow"] = "borrowed";
  TransactionTypePastTense["burn"] = "burnt";
  TransactionTypePastTense["cancel"] = "canceled";
  TransactionTypePastTense["claim"] = "claimed";
  TransactionTypePastTense["deploy"] = "deployed";
  TransactionTypePastTense["deposit"] = "deposited";
  TransactionTypePastTense["execute"] = "executed";
  TransactionTypePastTense["mint"] = "minted";
  TransactionTypePastTense["receive"] = "received";
  TransactionTypePastTense["repay"] = "repaid";
  TransactionTypePastTense["send"] = "sent";
  TransactionTypePastTense["sell"] = "sold";
  TransactionTypePastTense["stake"] = "staked";
  TransactionTypePastTense["trade"] = "swapped";
  TransactionTypePastTense["unstake"] = "unstaked";
  TransactionTypePastTense["withdraw"] = "withdrawn";
})(TransactionTypePastTense || (TransactionTypePastTense = {}));
